import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import moment from "moment";
import "moment-timezone";

import { useAddress } from '../../../contexts/AddressContext'
import { useOrder } from '../../../contexts/OrderContext'
import { SearchInput } from '../../Shared/SearchInput'
import { Accordion } from './Accordion'

import { useDelivery } from '../../../contexts/DeliveryContext'
import {
  ModalContainer,
  ModalDialog,
  ModalBody
} from '../../Shared/SideModal'
import { FixedHeader, GobackTitle } from '../../Layout/FixedHeader'
import {
  OneEle
} from './styles'

export const DeliveryModal = (props) => {

  const currentLng = localStorage.getItem('i18nextLng');
  const { closeHandleClick } = props
  const { t } = useTranslation();
  const [orderDetail, { updateOrder }] = useOrder();
  const [states, cities] = useAddress();

  const [searchedCities, setSearchedCities] = useState(cities)
  const selectedCity = parseInt(orderDetail.currentLocation)

  const [deliveryState] = useDelivery()


  const searchChange = (e) => {
    const searchText = e.target.value;
    const newArr = currentLng === 'en'
      ? cities.filter(item => item.name_en.toUpperCase().includes(searchText.toUpperCase()))
      : cities.filter(item => item.name_ar.toUpperCase().includes(searchText.toUpperCase()))
    setSearchedCities(newArr);
  }

  const mapData = (event) => {
    return parseFloat(event.hour)
  };
  const convertAmPm = (hour) => {
    return hour <= 11 ? hour + 'am' :
      hour === 12 ? hour + 'pm' : (hour - 12) + 'pm'
  }
  
  const handleSubmit = (stateId, cityId) => {
    let timeRange = [];
    if(deliveryState) {
      timeRange = deliveryState.map(mapData)
    }
    const currentHour = moment().tz("Asia/Kuwait").hour();
    const minTime = timeRange[0];
    const maxTime = timeRange[timeRange.length - 1];
    const isEnabledASAP = currentHour >= minTime && currentHour <= maxTime;
    const minDate = moment().tz("Asia/Kuwait").format('YYYY/MM/DD') //'2022/12/22'//currentHour + 1 < maxTime ? moment().tz("Asia/Kuwait").format('YYYY/MM/DD') : moment().add(1, 'day').tz("Asia/Kuwait").format('YYYY/MM/DD');
    //debugger
    updateOrder({
      currentState: stateId,
      currentLocation: cityId,
      timeRange: timeRange,
      maxTime: maxTime,
      minTime: minTime,
      isEnabledASAP: isEnabledASAP,
      minDate: minDate,
      deiveryTime: {
        date: minDate,
        time: isEnabledASAP ? convertAmPm(currentHour + 1) : convertAmPm(minTime + 1)
      }
    });
    closeHandleClick();
  }


  return (
    <ModalContainer>
      <ModalDialog>
        <FixedHeader>
          {currentLng === 'en'
            ? <FiArrowLeft size="20" onClick={closeHandleClick} />
            : <FiArrowRight size="20" onClick={closeHandleClick} />
          }
          <GobackTitle>{t('Delivery at')}</GobackTitle>
        </FixedHeader>
        <ModalBody noFooter={true}>
          <OneEle>
            <SearchInput onChange={searchChange} />
          </OneEle>

          {states?.length > 0 &&
            states.map(item => {
              const found = searchedCities.filter(obj => obj.state_id === item.id);
              return (
                found?.length > 0 &&
                <Accordion
                  key={item.id}
                  title={currentLng === 'en' ? item.name_en : item.name_ar}
                  found={found}
                  selectedCity={selectedCity}
                  handleSubmit={(_id) => handleSubmit(item?.id, _id)}
                />
              )
            })}
        </ModalBody>
      </ModalDialog>
    </ModalContainer>
  )
}