import React from 'react'
// import { useTheme } from 'styled-components'
import { RightTop } from './RightTop'
import { RightCenter } from './RightCenter'
import { useConfig } from '../../contexts/ConfigContext';

import {
  RightContainer,
  RightWrapper
} from './styles'

export const RightMain = ({ searchIconShowed, searchProducts, isProductSearched }) => {

  const [configState] = useConfig()
  //debugger
  // const theme = useTheme();
  const bgImage = configState?.configs?.background_image;

  return (
    <RightContainer bgimage={bgImage} id="rightContainer">
      <RightWrapper>
        <RightTop search={searchProducts} searchIconShowed={searchIconShowed && !isProductSearched} />
        <RightCenter />
      </RightWrapper>
    </RightContainer>
  )
}
