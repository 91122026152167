import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from "react-i18next";

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { useConfig } from '../../../contexts/ConfigContext';
import { useCategory } from '../../../contexts/CategoryContext'
import { useProduct } from '../../../contexts/ProductContext'

import {
  CategoryContainer,
  CategoryTitle,
  CategoriesWrapper,
  CategoryItem,
  CategoryImgWrapper,
  CategoryImg,
  CategoryName,
  ProductItem
} from './styles'

export const Category = (props) => {

  useTranslation();
  const { handleChangeCat, catId, scrolledCatId } = props
  const currentLng = localStorage.getItem('i18nextLng');
  const [configState] = useConfig()
  const [categories] = useCategory();
  const [products] = useProduct();

  const [swiper, setSwiper] = useState(null);

  return (
    <CategoryContainer viewType={configState?.configs?.categoryTypeView}>
      {configState?.configs?.categoryTypeView === 'list' &&(
        <>
         <Swiper
            key={currentLng}
            dir={currentLng === 'en' ? 'ltr': 'rtl'}
            slidesPerView={"auto"}
            onSwiper={setSwiper}
          >
            {categories.map(item =>{
              const prods = products.filter(obj => obj.cat_id === item.id);
              return (
                prods?.length>0 &&
                  <SwiperSlide key={item.id }>
                    <CategoryItem onClick={() => handleChangeCat(item.id)} active={(!catId && scrolledCatId === item.id) || (catId === item.id )}>
                      { currentLng ==='en' ? item.name_en : item.name_ar }
                    </CategoryItem>
                  </SwiperSlide>
              )
            })}
          </Swiper>
        </>
      )}
       
      {configState?.configs?.categoryTypeView === 'grid' && (
        <>
         <CategoryTitle>{t('Vendors')}</CategoryTitle>
          <CategoriesWrapper viewType={configState?.configs?.categoryTypeView}>
            {categories.filter(category => products.some(product => product?.cat_id === category.id))
              .map(item => (
                <ProductItem
                  key={item.id}
                  onClick={() => handleChangeCat(item.id)}
                  active={(!catId && scrolledCatId === item.id) || (catId === item.id)}
                  viewType={configState?.configs?.categoryTypeView}
                >
                  <CategoryImgWrapper>
                    <CategoryImg src={item.image} />
                  </CategoryImgWrapper>
                  <CategoryName>{currentLng === 'en' ? item.name_en : item.name_ar}</CategoryName>
                </ProductItem>
              ))}
          </CategoriesWrapper>
        </>
      )}
     
    </CategoryContainer>
  )
}