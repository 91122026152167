import React from 'react'
import { useTranslation } from "react-i18next";

import {
  PaymentHeaderContainer,
  HeaderImg
} from './styles';

export const PaymentHeader = () => {
  const {t} = useTranslation();
  return (
    <PaymentHeaderContainer>
      <HeaderImg>
        <svg width="1em" height="1em" viewBox="0 0 160 160"><g fill="none" fillRule="evenodd"><path stroke="#4A4A4A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M29 62.575v-7.46C29 46.8 35.8 40 44.111 40h58.542c8.31 0 15.111 6.801 15.111 15.114 0 0 .236 1.768.236 7.024m-84.22-2.583c1.15-6.652 6.931-11.75 13.835-11.75h54.398c7.723 0 14.042 6.38 14.042 14.178m-79.653-2.128c2.569-3.437 6.645-5.669 11.213-5.669h54.398c6.179 0 10.123 2.325 11.987 7.952m-69.796 56.987c-8.362 0-15.204-6.904-15.204-15.342V60.346h73.763c3.128 0 6.043.966 8.467 2.617m5.77 48.484c13.255 0 24-10.813 24-24.151 0-13.339-10.745-24.151-24-24.151s-24 10.812-24 24.15c0 13.339 10.745 24.152 24 24.152zM39 77.233v27.17m10 15.094h5m4.705-.216h56.24c2.1 0 3.802-1.719 3.802-3.829"></path><path fill="currentColor" d="M126.375 82.333c1.444 0 2.625 1.2 2.625 2.667v13.333c0 1.467-1.181 2.667-2.625 2.667h-15.75c-1.444 0-2.625-1.2-2.625-2.667V85c0-1.467 1.181-2.667 2.625-2.667h1.313v-2.666c0-3.68 2.94-6.667 6.562-6.667 3.623 0 6.563 2.987 6.563 6.667v2.666h1.312zM123 82v-2.745c0-2.347-2.018-4.255-4.5-4.255s-4.5 1.908-4.5 4.255V82h9zm-37 61.007a1 1 0 11-2 .025v-.01c.005 0 .002-.007 0-.014v-.006c-.005-.495-.177-3-3-3a1 1 0 01-.002-2c.502-.007 3.002-.177 3.002-3 0-.552.448-1.002 1-1.002s1 .45 1 1.002c0 2.823 2.502 2.993 3.005 3a1 1 0 01.025 2H89c-2.82 0-2.995 2.503-3 3.005zm20.6-115.604a.601.601 0 01-1.2.015v-.003s0-.003.003-.003l-.003-.009V27.4c-.003-.297-.108-1.8-1.8-1.8a.601.601 0 01-.003-1.2c.303-.003 1.803-.108 1.803-1.8a.6.6 0 111.2 0c0 1.692 1.5 1.797 1.803 1.8a.601.601 0 01.012 1.2h-.015c-1.692 0-1.797 1.5-1.8 1.803zm-87 53a.601.601 0 01-1.2.015v-.003s0-.003.003-.003l-.003-.009V80.4c-.003-.297-.108-1.8-1.8-1.8a.601.601 0 01-.003-1.2c.303-.003 1.803-.108 1.803-1.8a.6.6 0 111.2 0c0 1.692 1.5 1.797 1.803 1.8a.601.601 0 01.012 1.2H21.4c-1.692 0-1.797 1.5-1.8 1.803z"></path></g></svg>
      </HeaderImg>
      {t('Payment Method')}
    </PaymentHeaderContainer>
  )
}