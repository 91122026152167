import React from 'react'
import { BsSearch } from "react-icons/bs";

import { SearchContainer } from './styles'

export const SearchInput = (props) => {
    const { onChange } = props;
    return (
        <SearchContainer>
            <BsSearch />
            <input autoFocus type="text" name="deleiverySearch" autoComplete="off" onChange={onChange}/>
        </SearchContainer>
    )
}