import React from 'react'

export const CreditForm = () => {

  return (
    <div>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
      Need to Creat Creadit Form<br/>
    </div>
  )
}