import React from 'react'
import { BsSearch } from "react-icons/bs";
import Carousel from 'react-bootstrap/Carousel';

// import { SearchContainer } from './styles'
// const images = [
//   { src: 'https://demo.pos3.systems/images/products/1652192153.jpeg' },
//   { src: 'https://demo.pos3.systems/images/products/1652192153.jpeg' }
// ];
export const ProductsCarousel = (props) => {
    const { images, alt } = props;
    return (
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item
              key={index}
              className="demo-item"
          >
             <img
                className="d-block w-100"
                src={image.file_name}
                alt={alt}
              />
          </Carousel.Item>
        ))}
      </Carousel>
      
    //   <Carousel>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src="https://demo.pos3.systems/images/products/1652192153.jpeg"
    //       alt="First slide"
    //     />
    //     <Carousel.Caption>
    //       <h3>First slide label</h3>
    //       <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src="https://demo.pos3.systems/images/products/1652192153.jpeg"
    //       alt="Second slide"
    //     />

    //     <Carousel.Caption>
    //       <h3>Second slide label</h3>
    //       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    //   <Carousel.Item>
    //     <img
    //       className="d-block w-100"
    //       src="https://demo.pos3.systems/images/products/1652192153.jpeg"
    //       alt="Third slide"
    //     />

    //     <Carousel.Caption>
    //       <h3>Third slide label</h3>
    //       <p>
    //         Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    //       </p>
    //     </Carousel.Caption>
    //   </Carousel.Item>
    // </Carousel>
    )
}