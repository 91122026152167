import React, { createContext, useContext, useEffect, useState } from 'react'
import ApiService from '../../service/ApiService';

export const DeliveryContext = createContext()

export const DeliveryProvider = ({ children }) => {
  const [delivery, seDelivery] = useState([])

  const getDelivery = async () => {
    const res = await ApiService('/delivery', {}, 'get')
    seDelivery(res?.data);
  }

  useEffect(() => {
    getDelivery()
  }, [])

  return (
    <DeliveryContext.Provider value={[delivery]}>
      {children}
    </DeliveryContext.Provider>
  )
}

export const useDelivery = () => {
  const deliveryManager = useContext(DeliveryContext)
  return deliveryManager || [{}]
}
