import React from 'react'
import styled from 'styled-components'

export const RightTopContainerStyled = styled.div`
  text-align: ${props => props.theme.rtl ? 'left' : 'right'};
  font-size: 18px;
  color: ${({ theme }) => theme.colors.backgroundColor};
  padding: 25px 10%;
  display: flex;

  align-items: center;

  & span{
    cursor: pointer;
    font-weight: 600;
    ${props => props.theme.rtl ? '' : 'margin-top: -5px;'};
    &:hover{
      color: #ffc2a5;
    }
  }

  // @media (max-width: 990px) {
  //   padding: 5px 7%;
  //   span{
  //     &:hover{
  //       color: white;
  //     }
  //   }
  // }
`

export const SearchIconWrapper = styled.div`
  cursor: pointer;  
  ${props => props.theme.rtl ? 'margin-left: 10px' : 'margin-right: 10px'};
  ${() => 'font-size: 14px; width: 40px;height: 40px;background-color: #fff;color: #000;display: flex;justify-content: center;align-items: center;border-radius: 50%;}'};
  & > svg {
    width: 14px;
  };`
  
export const RightTopContainer = (props) => {
  return (
    <RightTopContainerStyled>
      {props.children}
    </RightTopContainerStyled>
  )
}
