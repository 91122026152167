import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { useTheme } from '../../../contexts/ThemeContext'

import {
  RightTopContainer,
  SearchIconWrapper
} from './styles'

export const RightTop = (props) => {

  const [theme, { update }] = useTheme()
  const { i18n } = useTranslation();

  const currentLng = localStorage.getItem('i18nextLng');
  const { search, searchIconShowed } = props

  // useEffect(() => {

  //   if (currentLng === 'ar') {
  //     document.body.dir = 'rtl';
  //   } else {
  //     document.body.dir = 'ltr';
  //   }
  // }, [currentLng]);

  const changeLanguage = () => {
    if (currentLng === 'ar') {
      i18n.changeLanguage("en")
      localStorage.setItem('i18nextLng', 'en')
      update({ ...theme, rtl: false });
    } else {
      i18n.changeLanguage("ar")
      localStorage.setItem('i18nextLng', 'ar')
      update({ ...theme, rtl: true });
    }
    
  }

  const searchOpen = () => {
    //debugger
    search(true);
    if(searchIconShowed)
    document.getElementById('rightContainer').classList.add("remove-image");
    else
    document.getElementById('rightContainer').classList.remove("remove-image");

  }

  return (
    <RightTopContainer>
      {searchIconShowed && (
        <SearchIconWrapper onClick={() => searchOpen()}>
          <BsSearch size={20} />
        </SearchIconWrapper>
      )}
      
      {currentLng === 'en'
        ? <SearchIconWrapper onClick={changeLanguage}><span>ع</span></SearchIconWrapper>
        : <SearchIconWrapper onClick={changeLanguage}><span>EN</span></SearchIconWrapper>
      }
    </RightTopContainer>
  )
}
