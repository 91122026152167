import styled from 'styled-components'

export const ContactContentContainer = styled.div`
  padding: 20px 15px;
`

export const ContactInfoContainer = styled.div`
`

export const ContactContentTItle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`

