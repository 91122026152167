import styled, { css } from 'styled-components'

export const CategoryContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  ${({ viewType }) => viewType === 'list' &&`
    padding: 15px;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: white;
    border-bottom: 0.55px solid rgba(0,0,0,0.1);
  `}
  

  .swiper-slide{
    width: fit-content !important;
    display: inline-block; 
    margin: 0px 10px;
  }
`
export const CategoryTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    background-color: #f5f7f9;
`
export const CategoriesWrapper = styled.div`
  width: 100%;
  margin-bottom: 65px;
  padding: 10px;
  display: flex;
  ${({ viewType }) => `
    justify-content: ${viewType === 'grid' ? 'space-between' : 'center'};
  `}
  flex-wrap: wrap;
`
export const CategoryItem = styled.div`
  border-radius: 30px;
  padding: 6px 15px;
  cursor: pointer;
  display: inline-block;
  transition: 0.3s all;
  ${props => props.active 
    ? css`background-color: #9c5a2e;
          border: none;
          color: white;
          `
    : css`background-color: #edecee;
          border: 1px solid #edecee;
          color: black;
          `
  }
`

export const ProductItem = styled.div`
  cursor: pointer;
  ${({ viewType }) => `
    width: ${viewType === 'grid' ? '48%' : '60%'};
    margin-bottom: ${viewType === 'grid' ? '10px' : '10px'};
  `}
`
export const CategoryImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CategoryImg = styled.img`
  width: 100%;
  border-radius: 12px;
  max-width: 200px;
`
export const CategoryName = styled.div`
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primaryIconCol};
`